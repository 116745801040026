








































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import CenterToFit16 from '@carbon/icons-vue/es/fit-to-screen/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import View16 from '@carbon/icons-vue/es/view/16'
import isEmpty from 'lodash/isEmpty'
import { namespace } from 'vuex-class'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

const eventsPermissionsModule = namespace('eventsPermissions')

@Component({
  components: {
    CenterToFit16,
    GenericModalForm,
    PasswordConfirmField,
    GenericModalDelete,
    TrashCan16,
    Edit16,
    View16
  }
})
export default class EventStudyReviews extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @eventsPermissionsModule.Getter getEventUserId!: (eventId: string) => string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) studyId!: string
  studyReviews = []
  filteredData = []
  studyReviewers = []
  studyReviewTypes = []
  selectedReviewTypeId = ''
  deleteModalIsOpened = false
  updateModalIsOpened = false
  reviewModalIsOpened = false
  selectedStudyReview = {
    id: ''
  }

  updateForm: Record<string, any> = {
    confirm: '',
    status: 'received',
    errors: {
      confirm: '',
      status: ''
    }
  }

  statuses = [
    {
      name: 'Recebido',
      key: 'received'
    },
    {
      name: 'Em revisão',
      key: 'reviewing'
    },
    {
      name: 'Aceito',
      key: 'accepted'
    },
    {
      name: 'Rejeitado',
      key: 'rejected'
    }
  ]

  event = {
    title: '',
    id: '',
    site: ''
  }

  created () {
    this.fetch()

    axios.get(`/event/${this.eventId}`)
      .then(response => {
        this.event = camelCaseKeys(response.data.data, { deep: true })
      })

    axios.get(`event/${this.eventId}/study/${this.studyId}/reviewer`)
      .then(response => {
        this.studyReviewers = camelCaseKeys(response.data.data, { deep: true })
      })

    axios.get(`/event/${this.eventId}/study-review-type`)
      .then(response => {
        this.studyReviewTypes = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  fetch () {
    axios.get(`event/${this.eventId}/study/${this.studyId}/reviews`)
      .then(response => {
        this.studyReviews = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  redirectToReview () {
    this.reviewModalIsOpened = false
    this.$nextTick(() => this.$router.push({
      name: 'EventStudyReviewCreate',
      params: {
        eventId: this.eventId,
        studyId: this.studyId,
        reviewTypeId: this.selectedReviewTypeId
      }
    }))
  }

  postDeleteSubmit (message: string) {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message,
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  postUpdateSubmit () {
    this.updateModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'Trabalho editado com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  // get isReviewerOfStudy () {
  //   const eventUserId = this.getEventUserId(this.eventId)
  //
  //   if (eventUserId) {
  //     return !!this.studyReviewers.find((reviewer: any) => reviewer.eventUser.id === eventUserId)
  //   } else {
  //     return false
  //   }
  // }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedStudyReview) ? '' : this.selectedStudyReview.id] }
  }
}
